import React, {Component} from 'react';
import H1 from "../headings/h1";
import H2 from "../headings/h2";

import {StripTags} from "../../../../src/helpers/MainHelper";

class Header extends Component {
    render() {
        let intro = '';

        if (this.props.intro !== undefined && this.props.intro.length > 0) {
            intro = <H2>{StripTags(this.props.intro)}</H2>
        }

        return <>
            <H1>{this.props.page_title}</H1>
            {intro}

            <div dangerouslySetInnerHTML={{__html:this.props.children}} />
            </>;
    }
}
export default Header;
